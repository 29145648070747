import './Styles.scss';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import DynamicDataButton from './DynamicDataNode';
import { MenuBar } from './MenuBar';
import { useState, useEffect } from 'react';
import { ArrowUturnLeftIcon, EyeIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import RefreshModal from './RefreshModal';
import PreviewModal from './PreviewModal';
import SaveModal from './SaveModal';
import Button, { ButtonVariant } from '../kit/Button';

const extensions = [
  DynamicDataButton,
  Link.configure({
    openOnClick: false,
    linkOnPaste: true,
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

interface Props {
  subject: string;
  body: string;
  onSubmit: any;
  userData: any;
  selectedContacts?: any[];
  submitText?: string
}
const TipTap = ({ subject, body, onSubmit, userData, selectedContacts, submitText = 'Save Template' }: Props) => {
  // State for editor content
  const [subjectContent, setSubjectContent] = useState(subject);
  const [bodyContent, setBodyContent] = useState(body);

  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  // Initialize editors with state
  const subjectEditor = useEditor({
    extensions,
    content: subjectContent,
  });

  const bodyEditor = useEditor({
    extensions,
    content: bodyContent,
  });

  const [activeEditor, setActiveEditor] = useState(subjectEditor);
  const [isSubject, setIsSubject] = useState(false);

  // Update editors when content changes
  useEffect(() => {
    if (subjectEditor && subject !== subjectEditor.getHTML()) {
      subjectEditor.commands.setContent(subject);
    }
  }, [subject, subjectEditor]);

  useEffect(() => {
    if (bodyEditor && body !== bodyEditor.getHTML()) {
      bodyEditor.commands.setContent(body);
    }
  }, [body, bodyEditor]);



  const onClick = (editor, isSubject) => {
    setIsSubject(isSubject);
    setActiveEditor(editor);
  };

  const onRefreshButtonClick = () => {
    setShowRefreshModal(true);
  };

  const onRefreshModalContinue = () => {
    subjectEditor?.commands.setContent(subject);
    bodyEditor?.commands.setContent(body);
    setShowRefreshModal(false);
  };

  return (
    <>
      {showSaveModal && (
        <SaveModal
          onClose={() => setShowSaveModal(false)}
        />
      )}

      {showRefreshModal && (
        <RefreshModal
          onClose={() => setShowRefreshModal(false)}
          onContinue={() => onRefreshModalContinue()}
        />
      )}

      {showPreviewModal && (
        <PreviewModal
          userData={userData}
          selectedContacts={selectedContacts}
          onClose={() => setShowPreviewModal(false)}
          subject={
            parseAndReplaceButtons(subjectEditor!.getHTML(), 'p') as string
          }
          body={parseAndReplaceButtons(bodyEditor!.getHTML()) as string}
        />
      )}

      <div className="space-y-4">
        {/* Subject Editor */}
        <div id='subject-editor' className="space-y-4">
          <label className="text-black text-lg">Subject</label>
          <div className="rounded-lg border-2 p-4 border-gray-300">
            <EditorContent
              editor={subjectEditor}
              onFocus={() => onClick(subjectEditor, true)}
            />
          </div>
        </div>

        {/* Body Editor with MenuBar */}
        <div className="space-y-4">
          <label className="text-black text-lg">Body</label>
          <div className="rounded-lg border-2 p-4 border-gray-300">
            <EditorContent
              editor={bodyEditor}
              className="focus:outline-none focus:ring-0 focus:border-transparent"
              onFocus={() => onClick(bodyEditor, false)}
            />
          </div>
          <MenuBar editor={activeEditor} isSubject={isSubject} />
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-9 space-y-4 sm:space-y-0">
          {/* Left Column */}
          <div className="flex justify-center sm:justify-start">
            <Button
              onClick={onRefreshButtonClick}
              id="reset-to-default-button"
              variant={ButtonVariant.TEXT}
              icon={<ArrowUturnLeftIcon />}
            >
              Reset To Default Message
            </Button>
          </div>

          {/* Middle Column */}
          <div className="flex justify-center">
            <Button
              onClick={() => setShowPreviewModal(true)}
              variant={ButtonVariant.SECONDARY}
              icon={<EyeIcon className="w-4 h-4 sm:mr-2" />}
            >
              See Email Preview
            </Button>
          </div>

          {/* Right Column */}
          <div className="flex justify-center sm:justify-end">
            <Button
              id="submit-template-button"
              variant={ButtonVariant.PRIMARY}
              icon={<HandThumbUpIcon />}
              onClick={() =>
                onSubmit(subjectEditor!.getHTML(), bodyEditor!.getHTML(), () =>
                  setShowSaveModal(true)
                )
              }
            >
              {submitText}
            </Button>
          </div>
        </div>


      </div>
    </>
  );
};

export function parseAndReplaceButtons(
  htmlString: string,
  additonalQuerySelector?: string
): string {
  // Create a temporary DOM element to parse the string
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Find all buttons with data-type="templatestring"
  const buttons = tempElement.querySelectorAll(
    'button[data-type="templatestring"]'
  );

  // Iterate over all matching buttons
  buttons.forEach((button) => {
    // Extract the templatestring attribute value or use an empty string if missing
    const templatestring = button.getAttribute('templatestring') || '';

    // Create a text node with the templatestring content
    const textNode = document.createTextNode(templatestring);

    // Find the parent span and replace it with the text node
    const parentSpan = button.closest('span');
    if (parentSpan) {
      parentSpan.parentNode?.replaceChild(textNode, parentSpan);
    }
  });

  // Return the updated HTML as a string
  if (additonalQuerySelector) {
    return tempElement.querySelector(additonalQuerySelector)
      ?.textContent as string;
  }
  return tempElement.innerHTML;
}

export default TipTap;
