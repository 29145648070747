import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';

import moment from 'moment';

import SortableTable from './SortableTable';
import ContactModal from './ContactModal';
import OnboardingModal from './onboarding/OnboardingModal';
import IntroActivityModal from './IntroActivityModal';
import IntroModal from './IntroModal';
import { Recipient, IntroductionTimeline } from './IntroTableRow';
import Toast from './Toast';
import Footer from './Footer';
import HomeCTA from './HomeCTA';
import HomeTabs from './HomeTabs';
import HomeSearch from './HomeSearch';
import CongratsModal from './Congrats';
import GotoAccountModal from './onboarding/GotoAccountModal';
import AddContactResponseModal from './AddContactResponseModal';
import CreateIntroResponseModal from './CreateIntroResponseModal';
import useGetFiltersTableVariables from '../hooks/useGetFiltersTableVariables';
import Spinner from './Spinner';
import AuthService from '../service/AuthService';
import UserDataService from '../service/UserDataService';
import { addAuthInterceptor } from '../service/AuthInterceptor';
import ContactService from '../service/ContactService';
import IntroductionService from '../service/IntroductionService';
import context from '../exports.json';
import Joyride from 'react-joyride';
import Icon from './Icon';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from './tour/Tooltip';
import {
  ChatBubbleBottomCenterIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import { STATUS } from 'common';
import Row from './intro-table/Row';
import StatusBadge from './StatusBadge';
import { listBlackListEntries } from '../graphql/queries';

/**
 * Home is the default logged in homepage of the app
 */
export default function Home() {
  const { userData, setUserData } = useOutletContext();
  /**
   * How long a toast should display for
   */
  const TOAST_TTL = 5000;

  /**
   * Date format
   */
  const DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
  const [filter, setFilter] = useState(undefined);
  const [activeTab, setActiveTab] = useState('intros');
  const [showIntroModal, setIntroModal] = useState(false);
  const [showNewContactModal, setNewContactModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [showAddContactResponseModal, setShowAddContactResponseModal] =
    useState(false);
  const [showCreateIntroResponseModal, setShowCreateIntroResponseModal] =
    useState(false);
  const [showComposeIntro, setShowComposeIntro] = useState(false);
  const [showSelectContacts, setShowSelectContacts] = useState(true);
  const [newContact, setNewContact] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const introColumns = ['recipients', 'status', 'intro timeline', 'actions'];

  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [introductions, setIntroductions] = useState([]);
  const [focusedIntroduction, setFocusedIntroduction] = useState(null);
  const [focusedContact, setFocusedContact] = useState(undefined);
  const [toasts, setToasts] = useState([]);
  const [showDashboardTour, setShowDashboardTour] = useState(false);
  const [isTutorialRunning, setTutorialIsRunning] = useState(false);
  const [shouldShowAccountTutorial, setShouldShowAccountTutorial] =
    useState(false);

  const [stepIndex, setStepIndex] = useState(0);
  const [loadingIntros, setLoadingIntros] = useState(true);
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const authService = new AuthService(context.ApiGatewayUrl);
  const userDataService = new UserDataService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );

  const contactService = new ContactService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );
  const introductionService = new IntroductionService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );

  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);

  /*
   * If your steps are not dynamic you can use a simple array.
   * Otherwise you can set it as a state inside your component.
   */
  const steps = [
    {
      data: {
        steps: 7,
        className: 'translate-y-40',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#table-row-0',
      content: (
        <>
          <p>
            Once you make an introduction, you can track its status and progress
            in this table.
          </p>
        </>
      ),
    },
    {
      data: { steps: 7, className: 'translate-y-32' },
      floaterProps: {
        hideArrow: true,
      },
      disableBeacon: true,
      target: '#table-row-0-2',
      content: (
        <>
          <p>
            Two weeks after every introduction, Looop will automatically ask
            your contacts how the intro went - no effort required on your part.
          </p>
        </>
      ),
    },

    {
      data: {
        steps: 7,
        className: 'translate-y-32',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#statusIcon', // Selects the action column
      content: (
        <>
          <p>
            If you want to pause an intro, simply click the pause button. To
            resume, hit play. It’s that easy.
          </p>
        </>
      ),
    },
    {
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      data: { steps: 7 },
      target: '#command-panel', // Selects the action column
      content: (
        <>
          <p>
            If you want to create a new introduction or add a new contact, you
            can do it right here.
          </p>
        </>
      ),
    },
    {
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      data: { steps: 7 },
      target: '#add-new-contact', // Selects the action column
      content: (
        <>
          <p>
            Let’s try adding someone new to your contacts. Click ‘Add New
            Contacts’
          </p>
        </>
      ),
    },
    {
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      data: { steps: 7 },
      target: '#table-row-0', // Selects the action column
      content: (
        <>
          <p>
            Awesome! You’ve successfully added your new contact. You can find
            the contact's details in this table.
          </p>
        </>
      ),
    },
    {
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      data: { steps: 7 },
      target: '#upload-csv', // Selects the action column
      content: (
        <>
          <p>
            If you need to upload multiple contacts, use this button to upload a
            CSV.
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    // if (showCongratsModal) {
    setIntroModal(false);
    contactService.list(filter).then((contacts) => {
      setContacts(contacts);
    });
    // }
  }, [showCongratsModal, filter]);

  const onStatusToggle = async (intro) => {
    const newStatus =
      intro.introStatus === STATUS.IN_PROGRESS.id
        ? STATUS.PAUSED.id
        : STATUS.IN_PROGRESS.id;

    await introductionService.toggleStatus(intro.id, newStatus);

    setIntroductions((prevIntroductions) =>
      prevIntroductions.map((item) =>
        item.id === intro.id ? { ...item, introStatus: newStatus } : item
      )
    );
  };
  /**
   * Convert the GraphQL Response of Introductions into a model better fit for
   * the react frontend. Specifically, Feedbacks are partitioned into attempts.
   *
   * @param {intro[]} Introductions Coming from a GraphQL Endpoint
   * @returns A List of Intros
   */
  const convertIntroToFrontEndModel = (intros) => {
    return intros.map((intro) => {
      const feedbacksByAtttempt = {};

      intro.feedback.items.forEach((feedback) => {
        if (!feedbacksByAtttempt[feedback.attempt]) {
          feedbacksByAtttempt[feedback.attempt] = [];
        }
        return feedbacksByAtttempt[feedback.attempt].push(feedback);
      });

      return {
        ...intro,
        status: Status[intro.status],
        feedback: feedbacksByAtttempt,
        responses: intro.feedback.items.filter((f) => f.fullfilled).length,
      };
    });
  };

  const [searchBy, setSearchBy] = useState('');
  const [variables, setVariables] = useState({});

  useEffect(() => {
    // console.log('loading contacts antes if', variables);
    if (!loadingContacts) return;
    // console.log('loading contacts despues if', variables);
    contactService.list();
  }, [loadingContacts, variables, searchBy]);

  useEffect(() => {
    if (!loadingIntros) return;

    // Load Intros
    introductionService
      .list()
      .then((intros) => {
        setIntroductions(intros);
        setShouldShowOnboarding(intros.length == 0);
        setTutorialIsRunning(intros.length == 0);
      })
      .finally(() => {
        setLoadingIntros(false);
      });
  }, [loadingIntros]);

  const introsToRows = introductions.map((i, index) => [
    <Recipient key={index} recipients={i.recipients} />,
    <StatusBadge status={STATUS[i.introStatus]} />,
    <Row introduction={i} onStatusToggle={() => onStatusToggle(i)} />,
    <button
      type="button"
      key={index}
      onClick={() => focusIntroduction(i)}
      id={`introduction-${index}-dots`}
    >
      ...
    </button>,
  ]);

  /**
   * Data Model transformed into array of primitives.
   */
  const contactsToRows = contacts.map((i, index) => {
    return [
      `${i.firstName} ${i.lastName}`,
      <span
        className={`px-2 py-1 rounded-full font-bold 
      ${
        i.isBlacklisted
          ? 'bg-red-100 text-red-600'
          : 'bg-green-100 text-green-700'
      }`}
      >
        {i.isBlacklisted ? 'Unsubscribed' : 'Subscribed'}
      </span>,
      i.email,
      // 0, // 'introductions', // Delete this column
      // 0, // 'feedback submissions', // Delete this column
      moment(i.createdAt).format(DATE_FORMAT),
      <button type="button" key={index} onClick={() => focusContact(i)}>
        ...
      </button>,
    ];
  });

  const contactColumns = [
    'contact',
    'Status',
    'email',
    // 'introductions',
    // 'feedback submissions', // Delete this column
    'date contact added',
    'actions',
  ];

  /**
   * Create a new Toast
   */
  const addNewToast = (toast) => {
    const newToasts = toasts;
    newToasts.push(toast);
    setToasts([...newToasts]);

    // Kill the Toast in 5 seconds
    setTimeout(() => removeToast(toast), TOAST_TTL);
  };

  /**
   * Remove Toast
   */
  const removeToast = (toast) => {
    const newToasts = toasts;
    const indexToRemove = newToasts.indexOf(toast);
    newToasts.splice(indexToRemove, 1);

    setToasts([...newToasts]);
  };

  const onSkipTutorial = () => {
    setShowDashboardTour(false);
    setTutorialIsRunning(false);
  }

  /**
   * Submit the introduction to the backend.
   * This also refreshes the state.
   */
  const submitIntroduction = async (selectedContacts, subject, body, cb) => {
    const recipients = selectedContacts;
    const newIntroduction = {
      Recipients: recipients.map((r) => r.email),
      Subject: subject,
      Body: body,
    };

    try {
      const response = await introductionService.create(newIntroduction);
      setIntroductions(await introductionService.list(filter));
      setUserData({
        ...userData,
        startedIntroductions: userData.startedIntroductions + 1,
      });
      if (cb) cb();
      addNewToast({
        text: "You've Created an Introduction",
        cta: 'Click Here',
        onClick: () => focusIntroduction(response),
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.status === 402) {
          // Handle 402 Payment Required error by redirecting to paywall
          navigate('/app/paywall');
          return;
        } else {
          // For other errors, show a toast notification
          addNewToast({
            cta: '',
            icon: (
              <Icon
                icon={<ExclamationCircleIcon />}
                iconClass="h-10 w-10 text-red-500"
              />
            ),
            textClass: 'text-md font-normal text-red-500',
            text: `Intro failed - ${e.response.data}`,
          });
        }
      }
    } finally {
      setShowSpinner(false);
      setIntroModal(false);
      setNewContactModal(false);
      setShowComposeIntro(false);
      setShowSelectContacts(true);
    }
  };

  /**
   * sets intros to be rendered
   */
  const renderIntros = () => {
    setActiveTab('intros');
  };

  /**
   * Render the contacts
   */
  const renderContacts = () => {
    setActiveTab('contacts');
  };

  /**
   * Render the intro modal
   */
  const renderIntroModal = async () => {
    if (
      userData.subscriptionState == 'active' ||
      new Date(userData.createdAt) <
        new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    ) {
      setIntroModal(!showIntroModal);
    } else {
      navigate('/app/paywall');
    }
  };

  /**
   * render the new contact modal
   */
  const renderNewContactModal = (tutorial?) => {
    setFocusedContact();
    setNewContactModal(!showNewContactModal);
  };

  /**
   * render the new cograts modal
   */
  const renderCongratsModal = () => {
    // setFocusedContact(); //TODO: set the contact
    setShowCongratsModal(!showCongratsModal);
  };

  /**
   * render add contact response modal
   */
  const renderAddContactResponseModal = () => {
    setShowAddContactResponseModal(!showAddContactResponseModal);
  };

  /**
   * render add contact response modal
   */
  const renderCreateNewIntroResponseModal = () => {
    setShowCreateIntroResponseModal(!showCreateIntroResponseModal);
  };

  /**
   * Focus a given Intro
   */
  const focusIntroduction = (intro) => {
    setFocusedIntroduction(intro);
  };

  /**
   * Focus a given contact
   */
  const focusContact = (contact) => {
    setFocusedContact(contact);
  };

  /**
   * On End Intro
   */
  const onEndIntro = () => {
    const newIntroductions = introductions;
    newIntroductions.find((i) => i.id === focusedIntroduction.id).status =
      Status.DEAD;
    setIntroductions([...newIntroductions]);
    handleOnEndIntro(focusedIntroduction, reloadData);
  };

  const onFilterChange = async (filter) => {
    setFilter(filter);
    const response = await introductionService.list({ filter });
    setIntroductions(response);
  };
  /**
   * On End Intro
   */

  const togglePauseIntro = () => {
    const newIntroductions = introductions;

    const foundIntro = newIntroductions.find(
      (i) => i.id === focusedIntroduction.id
    );

    foundIntro.status =
      foundIntro.status === Status.PAUSED ? Status.IN_PROGRESS : Status.PAUSED;

    setIntroductions([...newIntroductions]);
    introductionService.changeStatus();
  };

  const submitUpdateUserData = async (firstName, lastName) => {
    userDataService.update(firstName, lastName);
    setUserData({ ...userData, firstName, lastName });
  };
  /**
   * callback used when a submit button on the contacts modal is called.
   */
  const submitContact = (
    firstName,
    lastName,
    email,
    occupation,
    additionalNotes,
    id
  ) => {
    const submittedContact = {
      firstName,
      lastName,
      email,
      occupation,
      additionalNotes,
    };

    setNewContact({
      firstName: submittedContact.firstName,
      lastName: submittedContact.lastName,
      email: submittedContact.email,
    });
    /**
     * If we are updating, we will need an ID,
     */
    if (id) {
      submittedContact.id = id;
      editContact(submittedContact);
    } else {
      createNewContact(submittedContact);
    }

    if (isTutorialRunning) {
      setActiveTab('contacts');
      setTimeout( () => setStepIndex(5), 2000);
      setShowDashboardTour(true);
    }
  };

  /**
   * Update a Contact
   * @param {Object} submittedContact Contact to Update
   */
  const editContact = async (submittedContact) => {
    const returnedContact = await contactService.edit({
      Id: submittedContact.id,
      Email: submittedContact.email,
      FirstName: submittedContact.firstName,
      LastName: submittedContact.lastName,
      Occupation: submittedContact.occuptation,
    });

    const newContacts = contacts;
    const index = newContacts.indexOf(
      newContacts.find((c) => c.id === submittedContact.id)
    );
    newContacts[index] = submittedContact;
    setContacts(newContacts);
    addNewToast({
      text: 'Updated Contact',
      cta: 'View Here',
      onClick: () => focusContact(returnedContact),
    });
    setFocusedContact();
  };

  /**
   * Save a contact to the backend.
   * @param {Object} submittedContact The Cotact to save
   */
  /**
   ** callback used to create a new contact
   */
  const createNewContact = async (submittedContact) => {
    await contactService.create({
      Email: submittedContact.email,
      FirstName: submittedContact.firstName,
      LastName: submittedContact.lastName,
      Occupation: submittedContact.occupation,
    });

    setNewContactModal(false);
    setContacts(await contactService.list());
  };

  const tabs = [
    {
      name: 'Intros',
      href: '#',
      activeText: 'intros',
      current: 'page1',
      action: renderIntros,
      icon: <ChatBubbleBottomCenterIcon />,
    },
    {
      name: 'Contacts',
      href: '#',
      activeText: 'contacts',
      current: 'page2',
      action: renderContacts,
      icon: <UserGroupIcon />,
    },
  ];

  const [showNewContactCard, setShowNewContactCard] = useState(false);

  const searchIntrosByContact = async (contact) => {
    const intros = await introductionService.list({
      filter: filter,
      contact: contact.email,
    });
    setIntroductions(intros);
  };

  const handleShowAddNewContact = () => {
    setShowNewContactCard(!showNewContactCard);
    console.log(showNewContactCard);
  };

  useGetFiltersTableVariables(
    activeTab,
    searchBy,
    setVariables,
    setLoadingContacts,
    setLoadingIntros
  );

  return (
    <>
      <Joyride
        stepIndex={stepIndex}
        run={showDashboardTour}
        callback={(data) => {
          if (
            data.action === 'close' &&
            data.index === 4 &&
            data.lifecycle === 'complete'
          ) {
            setShowDashboardTour(false);
            renderNewContactModal(true);
            return;
          }

          if (
            data.action == 'close' &&
            data.lifecycle == 'complete' &&
            data.index === steps.length - 1
          ) {
            console.log(stepIndex);
            setStepIndex(1 + stepIndex);
            setShowDashboardTour(false);
            setShouldShowAccountTutorial(true);
            return;
          }

          if (data.action == 'close' && data.lifecycle == 'complete') {
            setStepIndex(1 + stepIndex);
          }
        }}
        steps={steps}
        tooltipComponent={Tooltip}
      />
      {shouldShowAccountTutorial && (
        <GotoAccountModal
          onClick={() => {
            navigate('/app/account?isTutorial=true');
          }}
          onSkip={() => {
            setShouldShowAccountTutorial(false);
            setShouldShowOnboarding(false);
          }}
        />
      )}
      {shouldShowOnboarding && (
        <OnboardingModal
          userData={userData}
          updateUserData={submitUpdateUserData}
          onCreateContact={submitContact}
          submitIntroduction={submitIntroduction}
          setShowSpinner={setShowSpinner}
          skipCallback={onSkipTutorial}
          contacts={contacts}
          onFinishOnboarding={() => {
            setShouldShowOnboarding(false);
            setShowDashboardTour(true);
            setTutorialIsRunning(true);
            setStepIndex(0);
          }}
        />
      )}

      <Spinner showSpinner={showSpinner} />
      <HomeCTA
        userData={userData}
        renderIntroModal={renderIntroModal}
        renderNewContactModal={renderNewContactModal}
        toggleNewContactCard={handleShowAddNewContact}
        introductions={introductions}
        showIntroModal={showIntroModal}
        showNewContactModal={showNewContactModal}
        contacts={contacts}
      />
      <div id="home-table" className="home-table">
        <div className="home-table__card">
          <HomeTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <HomeSearch
            showCSVOptions={activeTab === 'contacts'}
            onContactClick={searchIntrosByContact}
            contactService={contactService}
            searchBy={searchBy}
            setSearchBy={setSearchBy}
            onClear={async () => {
              setIntroductions(
                await introductionService.list(filter ? { filter } : undefined)
              );
            }}
            onFilterChange={(id) => onFilterChange(id)}
          />
          <div className="ml-4 mr-4 border border-t-0 border-gray-200 rounded" />
          {activeTab === 'contacts' ? (
            <SortableTable columns={contactColumns} rows={contactsToRows} />
          ) : (
            <SortableTable columns={introColumns} rows={introsToRows} />
          )}
        </div>
        <IntroModal
          userData={userData}
          onClose={() => setIntroModal(false)}
          isOpen={showIntroModal}
          onNewContactSubmit={submitContact}
          contacts={contacts}
          showComposeIntro={showComposeIntro}
          showSelectContacts={showSelectContacts}
          onIntroEditorSubmit={submitIntroduction}
          setIntroModal={setIntroModal}
          setShowSpinner={setShowSpinner}
        />

        <CongratsModal
          isOpen={showCongratsModal}
          onSubmit={() => {}}
          onClose={() => setShowCongratsModal(false)}
          contacts={contacts}
        />

        <AddContactResponseModal
          isOpen={showAddContactResponseModal}
          onSubmit={() => {}}
          onClose={() => setShowAddContactResponseModal(false)}
          contact={newContact}
          isTutorialRunning={isTutorialRunning}
        />

        <CreateIntroResponseModal
          isOpen={showCreateIntroResponseModal}
          onSubmit={() => {}}
          onClose={() => setShowCreateIntroResponseModal(false)}
          intro={newContact}
          error={true}
        />

        {focusedIntroduction && (
          <IntroActivityModal
            userData={userData}
            introduction={focusedIntroduction}
            isOpen={focusedIntroduction !== null}
            onPause={togglePauseIntro}
            onEnd={onEndIntro}
            onClose={() => setFocusedIntroduction(null)}
            onOpen={() => {
              if (showDashboardTour !== false) {
                setStepIndex(4);
                setShowDashboardTour(true);
              }
            }}
          />
        )}

        <ContactModal
          onClose={() => {
            setNewContactModal(false);
          }}
          isOpen={showNewContactModal}
          onSubmit={submitContact}
          isTutorialRunning={isTutorialRunning}
        />

        <ContactModal
          onClose={() => {
            focusContact();
          }}
          isOpen={focusedContact !== undefined}
          onSubmit={submitContact}
          contact={focusedContact}
        />

        <div className="container fixed bottom-0 w-screen p-4 mx-auto space-y-2 grid place-items-center">
          {toasts.map((toast, index) => {
            return (
              <Toast
                textClass={toast.textClass}
                text={toast.text}
                cta={toast.cta}
                onClick={toast.onClick}
                onClose={removeToast}
                icon={toast.icon}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}
