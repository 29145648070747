import { UserData } from 'common';
import emptyOrBlank from '../../Utilities';
import { useState } from 'react';
import Input from '../Input';
import Button, { ButtonVariant } from '../kit/Button';

interface Props {
  userData: any;
  onClick: (f, l) => {};
}

export default function PersonalInformation({ userData, onClick }: Props) {
  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const inputs = {
    firstName: {
      value: firstName,
      titleText: 'First Name',
      placeholderText: 'First',
      id: 'firstName',
      validationFunc: () => emptyOrBlank(firstName),
      required: true,
      type: 'input',
      onChange: (e) => setFirstName(e.target.value),
    },
    lastName: {
      value: lastName,
      titleText: 'Last Name',
      placeholderText: 'Last',
      id: 'lastName',
      validationFunc: () => emptyOrBlank(lastName),
      required: true,
      type: 'input',
      onChange: (e) => setLastName(e.target.value),
    },
  };

  return (
    <>
          <h4 className='text-black font-bold'>Your Personal Information</h4>
          <p className='text-black'>Keep your account information accurate and updated by managing it here.</p>

      <div className="sm:w-1/2 space-y-4">

        <div className="grid sm:grid-cols-2 grid-cols-1 pt-8 sm:space-y-0 space-y-2">
          {Object.keys(inputs).map((key) => {
            const i = inputs[key];
            return (
              <div className="px-8">
                <Input
                  validationFunc={i.validationFunc}
                  titleText={i.titleText}
                  placeholderText={i.placeholderText}
                  inputChangeCallback={(e) => i.onChange(e)}
                  value={i.value}
                  required={i.required}
                  type={i.type}
                />
              </div>
            );
          })}
        </div>
        <div className='px-8'>
        <Input
          validationFunc={() => emptyOrBlank('not')}
          disabled={true}
          titleText={'Email'}
          placeholderText=""
          inputChangeCallback={(e) => {}}
          value={userData.email}
          type={'input'}
          required={false}
        />
        </div>
        <div className="w-full px-8">
          <Button
            onClick={() => onClick(firstName, lastName)}
            variant={ButtonVariant.PRIMARY}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
