import {
  BoldIcon,
  ItalicIcon,
  LinkIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from "@heroicons/react/24/solid";
import { useCallback } from "react";
import Button, { ButtonVariant } from "../kit/Button";
import DynamicDataButton from "./DynamicData";

export const MenuBar = ({ editor, isSubject }) => {
  if (!editor) {
    return null;
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("Add a URL", previousUrl);
    if (url === null) return;
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  const buttons = [
    {
      label: "Bold",
      icon: <BoldIcon className="w-5 h-5" />, 
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: editor.isActive("bold"),
      disabled: isSubject || !editor.can().chain().focus().toggleBold().run(),
    },
    {
      label: "Italic",
      icon: <ItalicIcon className="w-5 h-5" />,
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: editor.isActive("italic"),
      disabled: isSubject || !editor.can().chain().focus().toggleItalic().run(),
    },
    {
      label: "Link",
      icon: <LinkIcon className="w-5 h-5" />,
      action: setLink,
      isHidden: editor.isActive("link"),
      disabled: isSubject,
    },
    {
      label: "Undo",
      icon: <ArrowUturnLeftIcon className="w-5 h-5" />,
      action: () => editor.chain().focus().undo().run(),
      disabled: !editor.can().chain().focus().undo().run(),
    },
    {
      label: "Redo",
      icon: <ArrowUturnRightIcon className="w-5 h-5" />,
      action: () => editor.chain().focus().redo().run(),
      disabled: !editor.can().chain().focus().redo().run(),
    },
  ];

  return (
    <div className="control-group">
      <div className="button-group flex sm:space-x-4 space-x-0">
        {buttons.map(({ label, icon, action, isActive, disabled, isHidden }, index) =>
          isHidden ? null : (
            <Button
              key={index}
              variant={ButtonVariant.TEXT}
              icon={icon}
              onClick={action}
              disabled={disabled}
            >
              <span className="hidden sm:inline">{label}</span>
            </Button>
          )
        )}
        <DynamicDataButton editor={editor} />
      </div>
    </div>
  );
};
