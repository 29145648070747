import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router';
import axios from 'axios';
import AuthService from '../service/AuthService';
import context from '../exports.json';
import { OAuthConfig } from 'common';

const authService = new AuthService(context.ApiGatewayUrl);
/**
 * Sign In with Google Button handles all Google stuff
 */
/* eslint-disable */
export default function SignInWithGoogle() {
  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    flow: OAuthConfig.FlowType,
    scope: [OAuthConfig.Scope],
    redirect_uri: window.location.origin,
    onSuccess: async (codeResponse) => {
      const tokenResponse = await axios.post(
        context.ApiGatewayUrl + 'api/auth/code',
        {
          authCode: codeResponse.code,
          redirectUri: window.location.origin,
        }
      );

      authService.setTokens(tokenResponse.data);
      navigate('/app/home');
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  if (authService.getToken()) {
    navigate('/app/home');
  }
  return (
    <div className="flex justify-center">
      <button type="button" onClick={googleLogin} className="google">
        <img
          className="google__logo"
          src="https://www.svgrepo.com/show/475656/google-color.svg"
          loading="lazy"
          alt="google logo"
        />
        <span>Sign Up with Google</span>
      </button>
    </div>
  );
}
