import React, { useEffect, useState } from 'react';
import './App.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from './icon/Logo.png';
import FAQs from './component/FAQs';
import Dashboard from './icon/Dashboard.png';
import HeroImage from './icon/HeroImage.png';
import HowItWorks from './icon/HowItWorks.png';
import { ANNUAL, MONTHLY } from './component/pricing/PricingCard';
import { Disclosure } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import Button from './component/kit/Button';

export default function Landing() {
  const nav = useNavigate();
  return (
    <div>
      <>
        <Navbar nav={nav} />

        <section
          id="home"
          className="grid grid-cols-2 gap-4 py-10 bg-gradient-to-tl from-75% to-emerald-50 from-white"
        >
          <div className="ml-auto w-2/5 text-left">
            <h1 className="text-3xl font-bold pt-8">
              Make Great Intros Fast. Keep Great Connections Forever.
            </h1>

            <p className="text-md font-thin py-5">
              Looop makes it easy to intro your network at scale, while also
              ensuring you keep in touch with every contact, connection, and
              outcome. Keep yourself in the loop.
            </p>

            <Button variant="primary" onClick={() => nav('/login')}>
              Get in the Looop
            </Button>
          </div>

          <div className="w-3/5">
            <img src={HeroImage}></img>
          </div>
        </section>

        <section
          id="about"
          className="items-center justify-center text-center bg-emerald-50"
          style={{ height: 'auto' }} // Let the height be dynamic based on the content
        >
          <div className="text-center">
            <h2 className="pt-16 text-3xl font-bold">
              Connect and Track with Ease
            </h2>
            <p className="text-md font-thin pt-5 w-3/12 mx-auto">
              Looop focuses solely on making and managing your introduction
              activities. Know exactly where all your introduction efforts are,
              and what you need to do next.
            </p>

            <div className="relative w-full pt-10">
              <img
                className="w-2/3 mx-auto h-auto object-cover"
                src={Dashboard}
                alt="Dashboard"
              />
            </div>
          </div>
        </section>

        <section
          id="how-it-works"
          className="flex items-center justify-center text-center bg-white py-24"
        >
          <div className="text-center">
            <h2 className="pt-4 text-3xl font-bold">How it Works </h2>
            <p className="text-md font-thin pt-5 w-80 mx-auto">
              Connecting people is just the first step.With Looop, its the only
              step you have to make.
            </p>

            <img className={'px-10 pt-10'} src={HowItWorks} />
          </div>
        </section>

        <section id="pricing" className="bg-gray-50 pb-32">
          <div className="grid grid-cols-3 gap-4 w-7/12 mx-auto pt-20">
            <div className="">
              <h2 className="text-3xl font-bold">
                Simple Pricing. Full Access to Features.{' '}
              </h2>
              <p className="text-md font-thin pt-5 ">
                Early access members get everything on one plan--monthly or
                annual, your call.
              </p>
            </div>

            <MONTHLY
              goto={() => nav('/app/home')}
              buttonText={'Start your Free Trial'}
            />
            <ANNUAL
              goto={() => nav('/app/home')}
              buttonText={'Start your Free Trial'}
            />
          </div>
        </section>

        <section
          id="faq"
          className="items-center justify-center text-center bg-white"
        >
          <FAQs />
        </section>
        <footer>
          <div className="w-3/4 mx-auto grid place-items-center space-y-6 container">
            {
              <>
                <div className="flex mt-12 space-x-3">
                  <img
                    className="w-auto h-8 lg:block"
                    src={Logo}
                    alt="Your Company"
                  />
                </div>
                <p className="text-gray-500">
                  Looop: Where connections come full circle
                </p>

                <div className="grid sm:grid-cols-6 grid-cols-3 gap-x-4 gap-y-8 text-sm text-center items-center">
                  <div className="col-span-1">
                    <a href="#about">About</a>
                  </div>
                  <div className="col-span-1">
                    <a href="#how-it-works">How it Works</a>
                  </div>
                  <div className="col-span-1">
                    <a href="#pricing">Pricing</a>
                  </div>
                  <div className="col-span-1">
                    <a href="/terms-of-service">Terms of Service</a>
                  </div>
                  <div className="col-span-1">
                    <a href="/privacy-policy">Privacy Policy</a>
                  </div>
                  <div className="col-span-1">
                    <a href="#faq">FAQs</a>
                  </div>
                </div>
              </>
            }

            <p className="pb-12 text-gray-500">
              &copy; 2025 Well Aware, Inc. All rights reserved.
            </p>
          </div>
        </footer>
      </>
    </div>
  );
}

function Navbar( {nav}) {
  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-10 pb-4">
      {({ open }) => (
        <>
          <div className="px-2 mx-auto max-w-8xl sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                <div className="flex items-center flex-shrink-0 sm:pl-16">
                  <a href="/app/home">
                    <img
                      className="block w-auto mt-2 h-8 lg:hidden"
                      src={Logo}
                      alt="Looop Logo"
                    />
                    <img
                      className="hidden w-auto mt-2 h-8 lg:block"
                      src={Logo}
                      alt="Your Company"
                    />
                  </a>
                </div>
              </div>

              {/* Centered Button Container */}
              <div className="hidden sm:flex absolute inset-y-0 left-1/2 transform -translate-x-1/2 items-center space-x-5">
                <a href="#about">About Looop</a>
                <a href="#how-it-works">How it Works</a>
                <a href="#pricing">Pricing</a>
                <a href="#faq">FAQs</a>
              </div>

              <div className="hidden md:block absolute inset-y-0 right-10 items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-4">
                <label htmlFor="dropdown">
                  <button
                    label="dropdown"
                    type="button"
                    id="dropdown"
                    className="p-1 text-gray-500 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  />
                </label>
                {/* Profile dropdown */}
                <Button variant="primary" onClick={() => 
                  
                  nav('/login')
                }>
                  Get Started
                </Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-2" />
            <a
              className="m-2 block text-center px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
              href="#about"
            >
              About Looop
            </a>
            <a
              className="m-2 block text-center px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
              href="#how-it-works"
            >
              How it Works
            </a>
            <a
              className="m-2 block text-center px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
              href="#pricing"
            >
              Pricing
            </a>
            <a
              className="m-2 block text-center px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
              href="#faq"
            >
              FAQs
            </a>
            <a
              href="/login"
              className="m-2 block text-center px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
            >
              Login
            </a>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
