import { ReactNode, ButtonHTMLAttributes } from "react";

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  OUTLINE = "outline",
  ACTION = "action",
  CANCEL = "cancel",
  TEXT = "text"
}

export enum ActionColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BLACK = "black",
  TERTIARY = "tertiary"
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: ButtonVariant;
  actionColor?: ActionColor;
  icon?: ReactNode;
}

interface ButtonStyles {
  baseStyle: string;
  variantStyles: Record<ButtonVariant, string | Record<ActionColor, string>>;
}

const buttonStyles: ButtonStyles = {
  baseStyle: `
    h-12 
    rounded-lg 
    text-base font-medium 
    flex items-center justify-center gap-2 
    transition-all duration-200
  `,
  variantStyles: {
    [ButtonVariant.PRIMARY]: "w-full sm:w-[200px] bg-primary text-white hover:bg-opacity-80 hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white text-[clamp(12px,1.5vw,16px)]",
    [ButtonVariant.SECONDARY]: "w-full sm:w-[200px] bg-secondary text-black hover:bg-opacity-80 border-2 hover:border-primary hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white",
    [ButtonVariant.OUTLINE]: "w-full sm:w-[200px] border border-text text-black hover:bg-primary hover:text-white hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white",
    [ButtonVariant.TEXT]: "w-fit px-4 h-[32px] bg-transparent text-[#080808] text-[14px] font-medium hover:text-[#080808] hover:font-semibold",
    [ButtonVariant.ACTION]: {
      [ActionColor.PRIMARY]: "w-full sm:w-12 bg-primary text-white hover:bg-opacity-80 hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white",
      [ActionColor.SECONDARY]: "w-full sm:w-12 bg-secondary text-black hover:bg-opacity-80 border-2 hover:border-primary hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white",
      [ActionColor.BLACK]: "w-full sm:w-12 bg-black text-white hover:bg-opacity-80 hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white",
      [ActionColor.TERTIARY]: "w-full sm:w-12 bg-tertiary text-white hover:bg-opacity-80 hover:text-black hover:outline-none hover:ring-2 hover:ring-primary hover:ring-opacity-50 hover:bg-white"
    },
    [ButtonVariant.CANCEL]: "w-full sm:w-[200px] bg-error text-white hover:bg-opacity-80"
  }
};

export default function Button({ 
  children, 
  variant = ButtonVariant.PRIMARY, 
  actionColor = ActionColor.PRIMARY,
  icon, 
  ...props 
}: ButtonProps) {
  const getVariantStyles = () => {
    const variantStyle = buttonStyles.variantStyles[variant];
    if (typeof variantStyle === 'string') {
      return variantStyle;
    }
    // If it's an action button, use the color variant
    return variantStyle[actionColor];
  };

  return (
    <button 
    className={`inline-flex items-center justify-center whitespace-nowrap ${buttonStyles.baseStyle} ${getVariantStyles()}`} 
    {...props}
  >
    {icon && <span className="w-4 h-4 me-2 flex-shrink-0">{icon}</span>}
    {children}
  </button>
  );
}